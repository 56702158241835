import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

export type NavLink = {
    href: string;
    text: string;
}

export type HeaderProps = {
    links: NavLink[];
}

export default class Header extends React.Component<HeaderProps> {

    render() {
        return (
            <Navbar id="header" className="header" collapseOnSelect bg="dark" expand="lg" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand href="#"></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="header-navbar" className="flex-justify-center">
                        <Nav>
                            {
                                this.props.links.map((link, ix) => {
                                    return (
                                        <Nav.Link href={link.href}>
                                            {link.text}
                                        </Nav.Link>
                                    )
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>   
        )
    }
}