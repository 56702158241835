import React from "react";
import Project, { ProjectProps } from "./project";
import RenderMarkdown from "../utils/render-markdown";
import { Carousel } from "react-bootstrap";

export type ShippedTitlesProps = {
    
}

export default class ShippedTitles extends React.Component<ShippedTitlesProps> {

    render() {
        return (
            <section id="games" className="topic-container">
                <h1>Games I've Worked On</h1>
                <Carousel interval={null}>
                    <Carousel.Item className="shipped-title">
                        <img src="https://upload.wikimedia.org/wikipedia/en/b/bd/Towerborne_cover_art.jpg" alt="Cover art for Towerborne by Stoic"/>
                        <div className="game-content">
                            <h2><a href="https://www.towerborne.com/">Towerborne</a> (2025)<br/>by <a href="https://stoicstudio.com/">Stoic Studio</a></h2>
                            <h4>Unreal Game Engine</h4>
                            <label><b>Role:</b> Contract UI dev working for <a href="https://www.uxisfine.com/">UXisFine</a></label>
                            <ul>
                                <li>General UI development, as well as improving the toolchain and UI dev processes to vastly improve iteration speed</li>
                                <li>Worked with other developers, artists, and designers in order to deliver high quality and highly performant UI within the constraints given</li>
                                <li>Worked to reduce tech debt and made major pushes to modify their pipeline to reduce bugs and improve iteration speeds</li>
                                <li>Leveraged experience as a TA to help other developers learn and develop new skills relevant to roles</li>
                            </ul>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="shipped-title">
                        <img src="https://upload.wikimedia.org/wikipedia/en/0/05/Ara_History_Untold_cover_art.jpg" alt="Cover art for Ara History Untold by Oxide"/>
                        <div className="game-content">
                            <h2><a href="https://www.arahistoryuntold.com/">Ara: History Untold</a> (2024)<br/>by <a href="https://www.oxidegames.com/">Oxide Games</a></h2>
                            <h4>Proprietary Engine</h4>
                            <label><b>Role:</b> Contract UI dev working for <a href="https://www.uxisfine.com/">UXisFine</a></label>
                            <ul>
                                <li>General UI development, as well as documenting and improving their existing UI toolchain</li>
                                <li>Worked with other developers, artists, and designers in order to deliver high quality and highly performant UI within the constraints given</li>
                                <li>Developed processes and tools to ease collaboration between developers and artists</li>
                            </ul>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="shipped-title">
                        <img src="images/shipped/asset-15.png" alt="Cover art for Asset 15 by 30 Ninjas"/>
                        <div className="game-content">
                            <h2><a href="https://www.asset15game.com/">Asset 15</a> (2023)<br/>by <a href="https://www.30ninjas.com/">30 Ninjas</a></h2>
                            <h4>Unity Game Engine (AR)</h4>
                            <label><b>Role:</b> Lead Developer</label>
                            <ul>
                                <li>Inherited project and brought it over the finish line, brought assets and effects to production-ready quality and performance</li>
                                <li>Managed a small team of developers to ensure that work was being done effectively and efficiently</li>
                                <li>Leveraged experience working in Volumetric Video Capture to apply to a shipping title</li>
                            </ul>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="shipped-title">
                        <img src="images/shipped/neurofit.png" alt="Cover art for Neurofit VR"/>
                        <div className="game-content">
                            <h2><a href="https://www.neurofit.ca/">Neurofit VR</a> (2021)<br/>(Gaming in the Medical Space)</h2>
                            <h4>Unity Game Engine (VR)</h4>
                            <label><b>Role:</b> Lead Software Engineer</label>
                            <ul>
                                <li>Led a small team of developers creating therapeutic exercises in Virtual Reality</li>
                                <li>Worked in both the Unity Game Engine, as well as in AWS and backend infrastructure</li>
                                <li>Developed back-end APIs and web portals for practitioners to track patient progress</li>
                                <li>Pushed for and enacted policies and procedures for PIPEDA and HIPPA compliance</li>
                            </ul>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>
        )
    }
}