import { AboutProps } from "./components/about";
import { ExperienceProps } from "./components/experience";
import { FooterProps } from "./components/footer";
import { NavLink } from "./components/header";
import { ImageCarouselImage, ImageCarouselImageType } from "./components/image_carousel";
import { ProjectProps } from "./components/project";
import { Skill } from "./components/skills";
import { LinkData } from "./types/link";

type payload = {
    nav_links  : NavLink[];
    footer     : FooterProps;
    about      : AboutProps;
    projects   : ProjectProps[];
    skills     : Skill[];
    experiences: ExperienceProps[];
}

let data : payload = {
    
    nav_links: [
        { href: "#about", text: "About" },
        { href: "#games", text: "Games" },
        { href: "#skills", text: "Skills" },
        { href: "#experience", text: "Experience and Education" },
        { href: "#projects", text: "Projects"},
        { href: "/content/Sage-Matthews-CV.pdf", text: "Resume/CV"}
    ],
    //
    footer: {
        bsky_handle:  "sage-made.ca",
        twitter_handle:  "SageMakesGames",
        gitlab_handle:   "SageMade",
        github_handle:   "SageMade",
        linkedin_handle: "sage-m-20716a134",
        itch_handle:     "sagemade"
    } as FooterProps,
    //
    about: {
        profile_pic: "images/profile-pic.png",
        pronouns: "she/they",
        description: [
            "Oh hey there! I'm Sage, a game dev from Toronto, Canada!",
            "I have years of experience in a variety of environments, from teaching others, to bootstrapping indies, to working on middleware, and even some codev work with AA and AAA game studios!",
            "I may be a game developer by training, but have a wide variety of experience in lots of different areas including cloud computing, micro-controllers, AR/VR, and volumetric video!",
            "I have a passion for learning new technologies and making cool things. Some of my hobbies include 3D printing, leather working, and making electronic doo-dads. I used to also do machining, but getting access to milling machines and lathes is hard!",
            "I'm looking to find a place where my passion for learning and variety of experiences can be put to use making cool stuff, whether it be games or software or anything that brings joy. Bonus points if I can push features that improve inclusivity and accessibility!"
        ]
    } as AboutProps,
    //
    projects: [
        // Publications     
        {
            id: "publications",
            title: "Publications",
            shortDesc: "Publications to academic journals",
            languages: [],
            images: undefined,
            description: [
                "I currently have two published articles from my time as a Masters student. Both relate to eye-tracking uses within Virtual Reality, which was a preliminary research topic to my thesis of eye guidance",
                "The first paper (*Rendering Optimizations for Virtual Reality Using Eye-Tracking*) can be found [here](content/svr-2020-sage.pdf) ([IEEE link](https://ieeexplore.ieee.org/document/9262667)). This paper is a literature review of foveation and how eye tracking can be used to improve VR rendering performance, as well as a review of research into the human visual system that underpins my thesis work.",
                "The second (*A Preliminary Eye Tracking and HMD Orientation Comparison to Determine Focus on a Cardiac Auscultation Training Environment*) is a paper discussing a methodology for monitoring gaze patterns in VR, and comparing different approaches. The paper can be found [here](content/iLRN-2021-sage.pdf) ([IEEE link](https://ieeexplore.ieee.org/document/9459383))"
            ],
            links: []
        } as ProjectProps,
        // OTTER
        {
            id: "otter",
            title: "OTTER Framework",
            shortDesc: "Framework for running C++ heavy coding courses",
            languages: [ "C++", "OpenGL", "Git" ],
            description: [
                "<[markdown/projects/otter.md]>"
            ],
            images: [ 
                {
                    src: "images/projects/otter/example-solution.png",
                    caption: "An example solution using the OTTER framework",
                    alt: "An example solution using the OTTER framework"
                } as ImageCarouselImage,
                {
                    src: "images/projects/otter/example-tools.png",
                    caption: "OTTER provides tools that ease solution and project generation",
                    alt: "Multiple command line windows showing batch commands from OTTER framework"
                } as ImageCarouselImage
            ],
            links: [
                { href: "https://github.com/Ontario-Tech-GDW/OTTER", text: "OTTER Repository", icon: "fa-github" } as LinkData,
                { href: "https://github.com/Ontario-Tech-GDW/TA-Tools", text: "TA Tools Repository", icon: "fa-github" } as LinkData
            ]
        } as ProjectProps,
        // Sandbox
        {
            id: "sandbox",
            title: "Graphics Sandbox",
            shortDesc: "Sandbox for graphics and game engine programming",
            languages: [ "C++", "OpenGL" ],
            description: [
                "<[markdown/projects/sandbox.md]>"
            ],
            images: [ 
                {
                    src: "images/projects/sandbox/editor.png",
                    caption: "The sandbox contains a WIP editor",
                    alt: "A view of my graphics sandbox editor showing a hierarchy, material manager, textures and an inspector"
                } as ImageCarouselImage,
                {
                    type: ImageCarouselImageType.Video,
                    src: "videos/projects/sandbox-live.mp4",
                    caption: "The game in play mode",
                    alt: "A spaceship flies around a demo scene with smoke and fire particles trailing behind it"
                } as ImageCarouselImage,
                {
                    type: ImageCarouselImageType.Video,
                    src: "videos/projects/phobos.mp4",
                    caption: "The older Phobos framework",
                    alt: "A scene of a starry background, with the camera on a plane that is rotating in space. Below is terrain and moving water"
                } as ImageCarouselImage
            ],
            links: [
                { href: "https://github.com/SageMade/OTTER/tree/AudioTests", text: "Sandbox Repository", icon: "fa-github" } as LinkData,
                { href: "https://gitlab.com/SageMade/phobos", text: "Phobos Repository", icon: "fa-gitlab" } as LinkData
            ]
        } as ProjectProps,
        // GameJams
        {
            id: "gamejams",
            title: "Game Jams",
            shortDesc: "Various game jam games!",
            languages: [ "C#", "Unity", "AWS" ],
            images: undefined,
            description: [
                "I've participated in a few game jams with friends, and have created a few fun games in the process! My jam games can be found on my [Itch.io](https://sagemade.itch.io) page, but here's the ones I'm most proud of",
                "<[markdown/projects/did.md]>",
                "<[markdown/projects/space-jalopy.md]>",
                "<[markdown/projects/return-to-colour.md]>",
            ],
            links: []
        } as ProjectProps,
        // NEXT 2020
        {
            id: "next-2020",
            title: "Ubisoft NEXT 2020 Rasterizer",
            shortDesc: "Hand-made 3D rasterizer using a 2D line Framework",
            languages: [ "C++", "OpenGL" ],
            description: [
                "Although I did not submit for NEXT 2020, I did take a crack at the framework they gave. I was a little disappointed to only see a 2D line renderer, so I decided to build a 3D line rasterizer on top of it to draw 3D wireframes!",
                "I wanted to keep to the spirit of NEXT and only use the provided IO systems, so I built a system on top of the line display that would take my 3D coords from IttyMath, and project (and clip) them into screenspace for rendering.",
                "I was pleasantly surprised by the performance, and even without GPU acceleration for rasterization was able to get multiple suzanne models rendering at the same time with updating model transforms",
                "I did not end up creating a game or submission for NEXT, but it was a fun experience applying my knowledge of rendering and rasterization to a constrained environment, and inspired me to create similar rasterizers to run on Arduino Nano and Uno microcontrollers!"
            ],
            images: [
                {
                    src: "images/projects/itty-libs/next-2020-engine.png",
                    caption: "Simple rasterizer created using IttyMath, with IttyECS instances (NEXT 2020)",
                    alt: "Image of two wireframe monkeys, rendered as part of engine tests using the NEXT 2020 framework"
                } as ImageCarouselImage,
                {
                    src: "images/projects/next-2020/render-core.png",
                    caption: "The core part of the line renderer, showing how we project and clip to 2D coordinates",
                    alt: "Source code for the core renderer showing the projection to 2D space, as well as clipping"
                } as ImageCarouselImage,
                {
                    type: ImageCarouselImageType.Video,
                    src: "videos/projects/next-2020/demo.mp4",
                    caption: "Simple rasterizer created using IttyMath, with IttyECS instances (NEXT 2020)",
                    alt: "Video of two wireframe monkeys, rendered as part of engine tests using the NEXT 2020 framework"
                } as ImageCarouselImage
            ],
            links: [
                { href: "https://gitlab.com/SageMade/next-2020", text: "GitLab link", icon: "fa-gitlab" }
            ]
        } as ProjectProps,
        // Itty Libs
        {
            id: "itty-libs",
            title: "IttyMath/IttyECS",
            shortDesc: "Single-header utility libraries",
            languages: [ "C++" ],
            description: [
                "Small, single-header libraries for math and entity-component systems that can be dropped into any project for rapid prototyping",
                "These libraries are not optimized to the extent of GLM or ENTT, but they are a handy tool for testing quick ideas!",
                "These were originally developed for use in Ubisoft's NEXT competition in 2020"
            ],
            images: [
                {
                    src: "images/projects/itty-libs/itty-math.png",
                    caption: "Itty Math, a lightweight math library",
                    alt: "Itty Math source preview"
                } as ImageCarouselImage,
                {
                    src: "images/projects/itty-libs/itty-math-usage.png",
                    caption: "Familiar structure to GLM, in a single header",
                    alt: "Itty Math usage example"
                } as ImageCarouselImage,
                {
                    src: "images/projects/itty-libs/itty-ecs.png",
                    caption: "IttyECS, a lightweight entity-component-system for prototyping",
                    alt: "Itty ECS source preview"
                } as ImageCarouselImage,
                {
                    src: "images/projects/itty-libs/itty-ecs-usage.png",
                    caption: "IttyECS makes creating component-gameobject relations simple",
                    alt: "Itty ECS usage example"
                } as ImageCarouselImage,
                {
                    src: "images/projects/itty-libs/next-2020-engine.png",
                    caption: "Simple rasterizer created using IttyMath, with IttyECS instances (NEXT 2020)",
                    alt: "Image of two wireframe monkeys, rendered as part of engine tests using the NEXT 2020 framework"
                } as ImageCarouselImage
            ],
            links: [
                { href: "https://gitlab.com/SageMade/itty-math", text: "GitLab link (IttyMath)", icon: "fa-gitlab" },
                { href: "https://gitlab.com/SageMade/itty-ecs", text: "GitLab link (IttyECS)", icon: "fa-gitlab" }
            ]
        } as ProjectProps,
        // Arduino
        {
            id: "arduino",
            title: "Arduino / Pico Pi Projects",
            shortDesc: "Various projects on Arduino and Pi",
            languages: [ "C" ],
            description: [
                "<[markdown/projects/arduino.md]>"
            ],
            images: [
                {
                    src: "images/projects/arduino/macrodeck.png",
                    caption: "The Macrodeck, my programmable macro keypad on Pico Pi",
                    alt: "A black box with 9 buttons, a dial, and an LED plugged into a computer"
                } as ImageCarouselImage,
                {
                    src: "images/projects/arduino/macrodeck-guts.png",
                    caption: "The guts of the Macrodeck, lovingly jammed inside it's plastic home",
                    alt: "A tangle of wires showing the inside of the Macrodeck"
                } as ImageCarouselImage,
                {
                    src: "images/projects/arduino/macrodeck-cad.png",
                    caption: "The design was created in Fusion 360 then 3D printed. (Multiple revisions were needed)",
                    alt: "An expanded view of the four base housing parts of the Macrodeck"
                } as ImageCarouselImage,
                {
                    src: "images/projects/arduino/macrodeck-programmer.png",
                    caption: "The Macrodeck programming interface",
                    alt: "An application with 9 buttons showing keyboard macros. The application is showing that it is connected to a serial port"
                } as ImageCarouselImage,
                {
                    type: ImageCarouselImageType.Video,
                    src: "videos/projects/arduino-display-advanced.mp4",
                    caption: "A 3D rasterizer running on an Arduino Mega and an OLED",
                    alt: "An OLED screen displaying a rotating 3D wireframe cube, with an Arduino Mega in the background"
                } as ImageCarouselImage
            ],
            links: [
                { href: "https://gitlab.com/SageMade/picopi-messaround", text: "My PicoPi Sandbox", icon: "fa-github" }
            ]
        } as ProjectProps
    ],
    //
    skills: [
        // C++
        {
            name: "C++",
            rank: 5,
            years: "10+ (5 professional)",
            note: "Language of choice; developed game frameworks, graphics projects throughout college and university. Used professionally in many projects"
        } as Skill,
        // C#
        {
            name: "C#",
            rank: 4,
            years: "10+ (5 professional)",
            note: "Developed Unity games, tools, and frameworks. Used professionally at Neurofit, Soar and 30 Ninjas. Also experienced with .Net reflection"
        } as Skill,
        // C
        {
            name: "C",
            rank: 4,
            years: "8+ (hobby programming)",
            note: "Used for microcontroller programming, as well as to optimize some parts of game frameworks and graphics projects"
        } as Skill,
        // Python
        {
            name: "Python",
            rank: 3,
            years: "2",
            note: "Used professionally to study neural networks at ORSIE, occasionally used for creating tooling"
        } as Skill,
        // Unreal
        {
            name: "Unreal Engine",
            rank: 3,
            years: "1",
            note: "Mostly Self-taught, used in some projects while working at UXisFine, likely new engine of choice"
        } as Skill,
        // Unity
        {
            name: "Unity Game Engine",
            rank: 4,
            years: "5 (5 professionally)",
            note: "Developed games in university. Used for personal projects and game jams. Used professionally at Neurofit, Soar and 30 Ninjas"
        } as Skill,
        // OpenGL
        {
            name: "OpenGL",
            rank: 4,
            years: "8+ (1 professional)",
            note: "Graphics API of choice for my projects. Used professionally at Soar for volumetric rendering. Used when making toy engines"
        } as Skill,
        // JS
        {
            name: "JavaScript",
            rank: 5,
            years: "9 Professionally",
            note: "Developed websites and APIs for Oiika, Neurofit, Soar, and some personal projects; Used for creating some game UIs in projects"
        } as Skill,
        // TS
        {
            name: "TypeScript",
            rank: 4,
            years: "2 Professionally",
            note: "Developed API backend at Soar in Typescript, as well as used for personal web projects"
        } as Skill,
        // SQL
        {
            name: "SQL",
            rank: 2,
            years: "6 (2 professionally)",
            note: "Used throughout college and for personal projects. Used professionally at Neurofit. Use Postgres as my DB engine of choice"
        } as Skill,
        // Git
        {
            name: "Git",
            rank: 4,
            years: "10+",
            note: "Used for (almost) all personal projects and professional work. (My git client of choice is [Git Fork](https://git-fork.com/)!)"
        } as Skill,
        // Perforce
        {
            name: "Perforce",
            rank: 3,
            years: "2",
            note: "Used in a professional setting, familiar with task streams and feature branching as well as swarm"
        } as Skill,
        // React
        {
            name: "React",
            rank: 3,
            years: "3 Professionally",
            note: "Used professionally at Oiika and Neurofit, also used to make this site!"
        } as Skill,
        // AWS
        {
            name: "AWS",
            rank: 3,
            years: "3 Professionally",
            note: "Developed and deployed infrastructure at Neurofit VR. Also used for personal projects"
        } as Skill,
        // GCP
        {
            name: "Google Cloud (GCP)",
            rank: 2,
            years: "1 Professionally",
            note: "Developed and deployed infrastructure at Soar"
        } as Skill,
        // Arduino
        {
            name: "Arduino",
            rank: 3,
            years: "5",
            note: "Used for personal projects and small DIY projects, would love to get into it more again!"
        } as Skill,
        // Pi Pico
        {
            name: "Pi Pico",
            rank: 2,
            years: "1",
            note: "Used for personal projects such as my MacroDeck"
        } as Skill,
    ],
    //
    experiences: [
        // DC
        {
            id: "dc",
            name: "Durham College",
            title: "Student",
            date_start: new Date(2014, 8),
            date_end:   new Date(2017, 4),
            url: "https://durhamcollege.ca/",
            logo: "",
            shortDesc: "Advanced Diploma\\\nComputer Programmer Analyst (Honours)",
            description_lines: [
                "Studied the basics of computer programming in multiple languages",
                "Learned database design and management (SQL, normalization, etc...)",
                "Examined networking at all layers, including configuring routers and switches",
                "Studied mainframe development in COBOL and JCL",
                "Graduated with honours"
            ],
            skills: [ "C++", "C#", "SQL", "Git", 'Javascript' ]
        } as ExperienceProps,
        // ORSIE
        {
            id: "orsie",
            name: "Office of Research Services, Innovation and Entrepreneurship",
            title: "Research Assistant",
            date_start: new Date(2017, 8),
            date_end:   new Date(2018, 4),
            url: "https://durhamcollege.ca/about/office-of-research-services-innovation-and-entrepreneurship-orsie",
            logo: "",
            shortDesc: "AI Researcher",
            description_lines: [
                "Researched applications of neural networks for use in video transcription",
                "Worked with a team to learn the concepts of neural networks and apply them to complex problems",
                "Built and deployed models using Python and Tensorflow"
            ],
            skills: [ "Python" ]
        } as ExperienceProps,
        // Undergrad
        {
            id: "uoit-undergrad",
            name: "Ontario Tech University (Undergrad)",
            title: "Undergraduate Student",
            date_start: new Date(2017, 5),
            date_end:   new Date(2019, 4),
            url: "https://ontariotechu.ca/",
            logo: "",
            shortDesc: "Bachelors in Information Technology\\\nGame Development and Entrepreneurship (with distinction)",
            description_lines: [
                "Practiced game engine design in C++ and OpenGL",
                "Studied both game development and game sales/marketing concepts",
                "Developed small games on a yearly basis in teams of ~6", 
                "Participated in LevelUp 2019, demonstrating a real-time Twitch integration toolset we developed",
                "Graduated with distinction"
            ],
            skills: [ "C++", "C#", "Git", 'Unity', 'OpenGL' ]
        } as ExperienceProps,
        // OIIKA
        {
            id: "oiika",
            name: "Oiika Inc",
            title: "CTO",
            date_start: new Date(2017, 10),
            date_end:   new Date(2018, 8),
            url: "https://durhamcollege.ca/about/office-of-research-services-innovation-and-entrepreneurship-orsie",
            logo: "",
            shortDesc: "Chief Technology Officer",
            description_lines: [
                "At the time of employment, was targeted towards connecting tutors and students",
                "Lead development of backend and frontend services",
                "Managed contact with business partners and service providers",
                "Worked to improve site stability and security"
            ],
            skills: [ "JavaScript", "React", "MongoDB" ]
        } as ExperienceProps,
        // Grad Studies
        {
            id: "uoit-grad",
            name: "Ontario Tech University (Masters) [DNF]",
            title: "Graduate Student",
            date_start: new Date(2019, 8),
            date_end:   new Date(2022, 4),
            url: "https://ontariotechu.ca/",
            logo: "",
            shortDesc: "Masters in Computer Science\\\n(Did not complete)",
            description_lines: [
                "Thesis based masters program",
                "Studied human visual system, and how to guide user vision in VR",
                "Developed small test applications that used aliasing in interfoveal region to guide vision", 
                "Prepared study design and ethics application for testing implementation",
                "Dropped out after COVID blocked the ability to perform any user trials"
            ],
            skills: [ "C++", "C#", "C", "Git", 'Unity', 'OpenGL' ]
        } as ExperienceProps,
        // TA
        {
            id: "uoit-ta",
            name: "Ontario Tech University (TA)",
            title: "Teacher's Assistant",
            date_start: new Date(2019, 8),
            date_end:   new Date(2022, 4),
            url: "https://ontariotechu.ca/index.php",
            logo: "",
            shortDesc: "",
            description_lines: [
                "Developed and led tutorial classes on introductory and intermediate graphics",
                "Created tools to ease teaching of concepts and grading of assignments",
                "Worked with students to drive interest in graphics and achieve learning goals",
                "Nominated for Teaching Assistant Excellence Award by students",
            ],
            skills: [ "C++", "OpenGL", "Git" ]
        } as ExperienceProps,
        // Neurofit
        {
            id: "neurofit",
            name: "Neurofit VR",
            title: "Lead Developer",
            date_start: new Date(2020, 8),
            date_end:   new Date(2022, 2),
            url: "https://www.neurofit.ca/",
            logo: "",
            shortDesc: "",
            description_lines: [
                "Lead development of Unity game, web backend, and user portal initiatives",
                "Created tools for authenticating users and ensuring platform security",
                "Developed novel storage solutions for dense data structure transmission over networks",
                "Reviewed and approved code from a small team of developers",
                "Improved infrastructure stability and scalability by moving services to distributed cloud compute",
                "Designed improved databases and migrated existing datasets",
                "Implemented policies to improve workflow and team cohesion"
            ],
            skills: [ "C#", "OpenGL", "Git", "Unity Game Engine", "JavaScript", "TypeScript", "AWS", "GCP" ]
        } as ExperienceProps,
        // Soar
        {
            id: "soar",
            name: "Soar Volumetric",
            title: "Software Engineer",
            date_start: new Date(2021, 9),
            date_end:   new Date(2022, 11),
            url: "https://streamsoar.com/",
            logo: "",
            shortDesc: "",
            description_lines: [
                "Architected and implemented audio systems for cross platform audio recording and playback",
                "Ported existing software solutions to other platforms (ex: Android)",
                "Worked with a wide range of native platforms and APIs",
                "Implemented web backend and cloud architecture to facilitate streaming and user management",
                "Created CI tools to allow for easy deployment and maintenance for web infrastructure",
                "Improved customer experience in Unity tools through API improvements and editor tools",
                "Developed new solutions and procedures to improve workflow",
                "Worked in a collaborative environment to solve problems in an efficient and fair manner"
            ],
            skills: [ "C++", "OpenGL", "Git", "TypeScript", "GCP", "Unity Game Engine", "SQL", "Firebase" ]
        } as ExperienceProps,
        // Freelancing
        {
            id: "freelancing",
            name: "Freelance Software Developer",
            title: "",
            date_start: new Date(2022, 10),
            date_end:   new Date(2024, 8),
            url: "",
            logo: "",
            shortDesc: "",
            description_lines: [
                "Performed freelance software development for multiple clients",
                "Worked with existing Unity game projects to fix bugs and optimize performance",
                "Used background in game design and development to assist clients in game design",
                "Worked on both Augmented and Virtual Reality experiences",
                "Investigated technical feasibility of new technologies",
            ],
            skills: [ "Git", "C#", "Unity Game Engine" ]
        } as ExperienceProps,
        // UXisFine
        {
            id: "uxif",
            name: "UXisFine",
            title: "",
            date_start: new Date(2023, 4),
            date_end:   undefined,
            url: "https://www.uxisfine.com/",
            logo: "",
            shortDesc: "",
            description_lines: [
                "Provided UI/UX expertise to multiple clients",
                "Worked with existing projects to fix bugs and optimize performance",
                "Helped develop processes and methodologies for artists and engineers",
                "Resolved tech debt and developed tools and technologies",
                "Helped other devs learn and develop new skills through collaborative coding",
            ],
            skills: [ "Git", "Perforce", "C++", "Javascript","Unity Game Engine", "Unreal Engine" ]
        } as ExperienceProps
    ]
};

export default data;