import './App.css';
import About from './components/about';

import data from "./data"
import Projects from './components/projects';
import { useEffect } from 'react';
import Skills from './components/skills';
import Footer from './components/footer';
import Experiences from './components/experiences';
import Header from './components/header';
import ShippedTitles from './components/shipped_titles';

function App() {
  useEffect(() => {
    document.title = "Sage Matthews"
  }, []);

  return (
    <div className="App">
      <Header links={data.nav_links} />
      <About {...data.about} />
      <ShippedTitles />
      <Skills skills={data.skills} />
      <Experiences experiences={data.experiences} />
      <Projects projects={data.projects} />
      <Footer {...data.footer} />
    </div>
  );
}

export default App;
